import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import * as React from 'react';
import styled from 'styled-components';

import {ContainerLg, ExternalLinkButton} from '../components/components';
import Layout from '../components/layout';
import MeetupInfoSection from '../components/sections/meetups/meetup-info-section';
import MeetupSponsorsSection from '../components/sections/meetups/meetup-sponsors-section';
import SpeakersTopicSection from '../components/sections/speakers-topic-section';
import {breakpointsMediaQueries, COLORS} from '../style/my-theme';

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: {fields: frontmatter___order, order: ASC}
      filter: {fileAbsolutePath: {regex: "/speakers/meetups/"}}
    ) {
      edges {
        node {
          id
          body
          slug
          frontmatter {
            order
            fullName
            title
            company
            talkTitle
            website
            github
            linkedin
            twitter
            imageSrc {
              childImageSharp {
                large: gatsbyImageData(
                  height: 180
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
                small: gatsbyImageData(
                  height: 120
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            previewSrc {
              childImageSharp {
                gatsbyImageData(height: 340, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
`;

const StyledMeetupTitleSection = styled.section.attrs({
  className: 'meetup-title-section'
})`
  height: 360px;
  font-family: 'Montserrat', sans-serif;
  position: relative;

  .hero-logo {
    display: flex;
    max-width: 800px;
    flex-direction: column;
    margin: 0;
    position: absolute;
    left: 25%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    ${breakpointsMediaQueries.md} {
      left: initial;
      color: ${COLORS.primary};

      .logo-img {
        display: none;
      }
    }
    .subtitle {
      text-align: center;
      font-size: 1.5rem;
    }
    .hero-logo-wrapper {
      display: flex;
      ${breakpointsMediaQueries.md} {
        flex-direction: column;
      }
    }

    .title {
      margin-left: 4rem;
      text-align: right;

      ${breakpointsMediaQueries.md} {
        margin-left: 0;
        text-align: center;
      }

      h1 {
        font-size: 2rem;
        font-weight: 600;
        margin: 0;
        border-bottom: 4px ${COLORS.blueLight} solid;
      }
      h2 {
        font-size: 1.5rem;
        margin: 0;
      }
      h3 {
        font-size: 1rem;
        margin: 0;
      }
      .button-link-wrapper {
        justify-content: right;
        ${breakpointsMediaQueries.md} {
          justify-content: center;
        }
      }
    }
  }
`;

const MeetupsPage = ({data}: {data: any}) => {
  const speakers = data.allMdx.edges;

  return (
    <Layout
      title="Armada JS - Meetups"
      description="Visit our Armada JS meetups to learn more about what's new in the developer community, listen to talks from some of the best in the industry, and stay for our great networking events. Visit the link and register on time!"
      imageUrl="https://armada-js.com/images/meetup-1-preview.png"
    >
      <ContainerLg>
        <StyledMeetupTitleSection>
          <div className="hero-logo">
            <div className="hero-logo-wrapper">
              <StaticImage
                className="logo-img"
                width={220}
                height={250}
                objectFit="contain"
                src="../images/logo-large.png"
                alt="Armada JS Logo"
                placeholder="none"
              />
              <div className="title">
                <h1>Meetup #1</h1>
                <h2>Tuesday, March 28th, 2023</h2>
                <h3>18 - 22h</h3>
                <h3>ICT Hub, Kralja Milana 10, Belgrade</h3>
                <ExternalLinkButton
                  url="https://forms.gle/7y5hqE1T7dLMUs6ZA"
                  label="REGISTER HERE"
                />
              </div>
            </div>
          </div>
        </StyledMeetupTitleSection>
      </ContainerLg>
      <SpeakersTopicSection speakers={speakers} />
      <MeetupInfoSection />

      <MeetupSponsorsSection />
    </Layout>
  );
};
export default MeetupsPage;
