import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot, faCalendar, faStopwatch} from '@fortawesome/free-solid-svg-icons';

import {ContainerLg} from '../../components';
import {breakpointsMediaQueries} from '../../../style/my-theme';

const SECTION_INFO = {
  title: 'WHERE AND WHEN ?',
  location: 'ICT Hub, Kralja Milana 10, Belgrade',
  date: 'Tuesday, March 28th, 2023',
  time: '18:00 - 22:00h'
};

const StyledMeetupInfoSection = styled.section.attrs({
  className: 'venue-section'
})`
  display: grid;
  grid-template-columns: auto auto;
  color: #fff;
  background-image: url('/images/footer-stickers.jpeg');

  ${breakpointsMediaQueries.md} {
    grid-template-columns: auto;
  }

  .container-lg {
    ${breakpointsMediaQueries.md} {
      margin: 20px 40px;
    }
    .venue-info {
      display: flex;
      flex-direction: column;
      span {
        display: flex;
        gap: 1rem;
        align-items: center;
        text-align: left;
        svg {
          width: 1rem;
          color: #f6df19;
        }
      }
    }
  }
  .mapouter {
    position: relative;
    margin-left: auto;
    text-align: right;
    height: 400px;
    width: 100%;
    .gmap_canvas {
      overflow: hidden;
      background: none !important;
      height: 400px;
    }
  }
`;

const MeetupInfoSection = () => (
  <StyledMeetupInfoSection>
    <ContainerLg>
      <h2>{SECTION_INFO.title}</h2>
      <div className="venue-info">
        <span>
          <FontAwesomeIcon icon={faLocationDot} />
          {SECTION_INFO.location}
        </span>
        <span>
          <FontAwesomeIcon icon={faCalendar} />
          {SECTION_INFO.date}
        </span>
        <span>
          <FontAwesomeIcon icon={faStopwatch} />
          {SECTION_INFO.time}
        </span>
      </div>
    </ContainerLg>
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11322.234126330326!2d20.4617682!3d44.8101849!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7a87b69c996d%3A0x2936b30b58b79674!2sICT%20Hub%20Kralja%20Milana!5e0!3m2!1sen!2srs!4v1679417784787!5m2!1sen!2srs"
          width="100%"
          height="450"
          style={{border: 0}}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  </StyledMeetupInfoSection>
);

export default MeetupInfoSection;
